
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { config } from "@/core/helpers/config";
import ReceiptsTable from "@/components/reusable/ReceiptsTable.vue";
import InvoicesTable from "@/components/reusable/InvoicesTable.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "contents-house-insurance-policy-information",
  components: {
    ReceiptsTable,
    InvoicesTable,
    GoBackButton
  },
  data() {
    return {
      policySummary: {},
      items: [],
      receipts: [],
      invoices: [],
      publicId: this.$route.params.publicId,
      route: ""
    };
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageBreadcrumbs("Contents", [
        "Policy",
        "Policy Information",
        "House Insurance"
      ]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      setActiveTab
    };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Policy information route based on user roles
    if (userType.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = `${variables.POLICIES_ROUTE}/${this.publicId}`;
    } else if (userType.role == variables.BROKER_USER_ROLE) {
      // Broker role policy information route
      this.route = `${variables.BROKER_CLIENT_POLICY_DETAILS_ROUTE}/${this.publicId}`;
    } else if (userType.role == variables.ADMIN_USER_ROLE) {
      // Super Admin & Admin role policy information route
      this.route =
        variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE + this.publicId;
    }

    // Get policy information
    this.getPolicyInformation();

    //Set page title
    document.title =
      "Contents House Insurance Policy Information | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getPolicyInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to variables
            this.policySummary = data.data.policySummary;
            this.items = data.data.items;
            this.receipts = data.data.receipts;
            this.invoices = data.data.invoices;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    }
  }
});
